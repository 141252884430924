.accordion {
  margin-bottom: 60px;

  & + & {
    margin-top: -60px;
  }

  &__details {
    appearance: none;
  }

  &__summary {
    padding-top: 20px;
    padding-bottom: 10px;
    // Both appearance: none and list-style: none are needed to remove the arrow
    appearance: none;
    list-style: none;
    @include accentSite(color);
    border-bottom: 1px solid;

    &::-webkit-details-marker {
      display: none;
    }
  }

  // Safari needs an inner wrapper for flex to work properly in summary
  &__summary-inner {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    @include headingSecondary;
    @include accentSite(color, 11);
    flex: 1 1 auto;
    cursor: pointer;
  }

  &__open,
  &__closed {
    margin-right: 20px;
    margin-left: 10px;
    @include accentSite(color);
  }

  &__open {
    display: none;

    .accordion__details[open] & {
      display: inline;
    }
  }

  &__details[open] &__closed {
    display: none;
  }

  &__text {
    padding-top: 20px;
  }

  .section-spacing-bottom {
    padding-bottom: 60px;

    &.small {
      padding-bottom: 30px;
    }
  }
}