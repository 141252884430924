.column-quote {
  // Width class options
  @each $mod, $width in $columnContentWidths {
    &.width-#{$mod} {
      @include respond($break60) {
        max-width: #{$width}px;
      }
    }
  }

  @include iconPre($quoteLeft);
  position: relative;
  display: block;
  padding: 35px 28px 60px;
  font-size: 16px;
  line-height: 1.333;
  color: transparentize($neutralBlack, 0.3);
  text-align: left;
  letter-spacing: 0.062em;
  outline: none;

  @include respond($break50) {
    font-size: 18px;
  }

  @include respond($break60) {
    padding: 8.5vw 5vw 9vw;
    text-align: center;
  }

  @include respond($break80) {
    padding-bottom: 8vw;
  }

  @include respond($break90) {
    font-size: 20px;
  }

  @include respond($break110) {
    padding: 80px 70px 95px;
  }

  &:hover {
    .more {
      opacity: 1;

      &::after {
        transform: translateX(8px);
      }
    }
  }

  &:not([class*='bg-accent-']) {
    // Default color
    background-color: nth($accentSecondary, 5);
  }

  &.escape {
    // Change padding on mobile only
    @include respond($break60, max) {
      padding-right: calc(28px + #{$containerPaddingResp});
      padding-left: calc(28px + #{$containerPaddingResp});
    }
  }

  &.bare {
    // Less bottom padding on mobile only
    @include respond($break60, max) {
      padding-bottom: 23px;
    }

    &::before {
      display: none;
    }
  }

  .left & {
    margin: 0 0 0 auto;
  }

  &::before {
    display: block;
    padding-bottom: 34px;
    margin-left: -6px;
    font-size: 10px;
    text-align: left;

    @include respond($break60) {
      position: absolute;
      top: 43px;
      left: 35px;
      padding-bottom: 0;
    }
  }

  &.width-30 {
    // Reduced padding for smaller widths
    @include respond($break110) {
      padding: 95px 65px 120px;
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.large {
    @include respond($break60) {
      font-size: 22px;
    }

    @include respond($break90) {
      font-size: 28px;
    }

    &.width-45, &.width-100 {
      @include respond($break90) {
        font-size: 24px;
      }
    }
  }

  .more {
    @include iconPost($arrowRight);
    display: block;
    margin-top: 23px;
    margin-right: -6px;
    color: transparentize($neutralBlack, 0.3);
    text-align: right;
    opacity: 0;
    transition: opacity $duration $timing,
      transform $duration $timing;

    @include respond($break60) {
      margin-top: 34px;
      margin-bottom: -8px;
    }

    @include respond($break65) {
      margin-bottom: -26px;
    }

    @include respond($break80) {
      margin-bottom: -35px;
    }

    &::after {
      padding-left: 13px;
      margin-top: 2px;
      font-size: 9px;
      transition: transform $duration $timing;
      transform: translateX(0);
    }
  }
}
